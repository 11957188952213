import { useStore } from 'vuex';
/**
 * 功能：通过字段映射颜色值
 */

export const mark: any = {

  // 线索类型, 客户类型
  cusTypeStr(label: string){
    return {
      label, 
      color: '#165DFF',
      background: 'rgba(22, 93, 255, 0.1)'
    }
  },

  // 客户意向
  intention( label: string ) {
    const { state: { sClueDetail } } = useStore();
    const defaultOpt = { 
      color: '#FF6159',
      background: 'rgba(235, 21, 11, 0.1)',
      label,
    }
  
    if( sClueDetail?.clueDict?.CUSTOMER_INTENTION ){
      const { clueDict: { CUSTOMER_INTENTION } } = sClueDetail;
      const curItem = CUSTOMER_INTENTION.find( (item: any ) => item._value === label );

      defaultOpt.label = curItem && curItem._label;
       
      switch( defaultOpt.label ){
        case '有意向': return {
          ...defaultOpt,
          color: '#31D158',
          background: 'rgba(49, 209, 88, 0.1)'
        }
        case '未明确': return {
          ...defaultOpt,
          color: '#FF7A00',
          background: 'rgba(255, 122, 0, 0.1)'
        }
        default: return defaultOpt;
      }
    }
    return defaultOpt
  },

  // 线索来源 clueSource 
  clueSource( label: string ){
    switch( label ){
      case '左心房投放': return {
        label,
        icon: require( '@/assets/img/zxf.png' ),
        color: '#E40020',
        background: 'rgba(228, 1, 32, 0.1)',
      }
      case '左心房腾讯投放': return {
        label,
        icon: require( '@/assets/img/zxf.png' ),
        color: '#E40020',
        background: 'rgba(228, 1, 32, 0.1)',
      }
      case '友商推送': return {
        label,
        icon: require( '@/assets/img/hz.png' ),
        color: '#2298E0',
        background: 'rgba(34, 152, 224, 0.1)',
      }
      case '金蝶字节投放': return {
        label,
        icon: require( '@/assets/img/jd_ldy_icon.png' ),
        color: '#2298E0',
        background: 'rgba(34, 152, 224, 0.1)',
      }
    }
  },

  // 商机或归属类型
  belongType( label: string){
    return  this.cusTypeStr( label )
  },

  // 重复线索标识
  globalRepeat( val: Boolean){
    return val ? {
      label: '重复',
      color: '#FF7A00',
      background: 'rgba(255, 122, 0, 0.1)'
    } : '';
  }
}


/**
 * 功能：线索标记颜色处理
 * customer.intention: 客户意向
 * customer.cusTypeStr：线索类型, 客户类型
 * clueSource: 线索来源
 * belongType: 商机或归属类型
 * globalRepeat: 是否重复
 */
export const markList = (data: any): any[] => {
  const arr: any[] = [];
  Object.entries( data ).forEach( ([ key, value ]) => {
    if( value ){
      arr.push( mark[key](value) );
    }
  });
  return arr
}