/**
 * @author lantian
 * @desc：所有的 actions 均使用 a 开头
 */

import { aClueDetail } from 'apis';
import { ENUM } from 'conf';
import { isEmptyObject } from 'tools';
import { message, alert } from 'hooks';
import { dictDataFormat, handleParams, handleFollowRecord } from './tools';
const { CLUE_DICT, FOLLOW_TYPE } = ENUM;

/**
 * 功能：获取线索字典集合
 */
export const aGetClueDict = async (ctx: any) => {
  const bizCode = CLUE_DICT[ '全部' ];
  const { state, commit } = ctx;
  const isEmpty = isEmptyObject( state.clueDict );
  
  if( !isEmpty ) return;

  try{
    const { data: { code, data } } = await aClueDetail.clueDict({ bizCode });
    if( code == 200 ){
      const ret = dictDataFormat( data.list );
      console.log( ret, '----------------dict-----------------' );
      commit( 'mGetClueDict', ret );
      return Promise.resolve( ret );
    }
  }catch(e){
    console.log(e);
    return Promise.reject()
  }
}

/**
 * 功能：获取详情信息
 */
export const aGetClueDetailInfo = async ( ctx: any ) => {
  const { state: { info: { clueId, _clueType } }, rootState: { userInfo: { isPlatform } }, commit } = ctx;
  if( !clueId ) return; 
  try{
    const { data } = await aClueDetail[ isPlatform ? 'getById' : 'getClueByIdV3' ]( clueId );
    data.code === 200 && commit( 'mGetInfo', { ...data.data, _clueType });
    return Promise.resolve( data );
  }catch(e){
    return Promise.reject();
  }
}

/**
 * 功能：写跟进
 */
export const aCreateV4 = async ( ctx: any, payload: any ) => {
  const { dispatch } = ctx;
  const params = handleParams( ctx, payload );

  console.log( params, '-----------------params-------------' );

  const { data: { code, data } } = await aClueDetail.createV4( params );

  if( code == 200 ){
    if (!data) {
      message.success("操作成功");
      dispatch("aGetClueDetailInfo");
      dispatch("aFollowRecord");
    }
    return Promise.resolve(data);
  }
  return Promise.reject()
}

/**
 * 功能： 获取已签约， 合同信息
 */
export const aGetOrderInfo = async ( ctx: any ) => {
  const { commit, state: { info: { orderId, clueId } } } = ctx;

  if( !orderId ) return Promise.reject();

  try{
    const { data: { code, data } } = await aClueDetail.order( orderId );
    if( code == 200 ){
      const d = { ...data, id: orderId, clueId };
      commit( 'mOrderInfo', d );
      return Promise.resolve( d );
    }
    return Promise.reject();
  }catch(e){
    return Promise.reject();
  }
}

/**
 * 功能： 获取批复详情
 */
export const aReply = async ( ctx: any ) => {

  const { commit, state: { info: { orderId, clueId } }, getters } = ctx;
  const { serveStatus: { next } } = getters;
  // 已批复 APPROVED
  if( !orderId || next._value === 'APPROVED' ) return Promise.reject( 'have not orderId or next is APPROVED' );

  try{
    const { data: { code, data } } = await aClueDetail.reply( orderId );
    if( code == 200 ){
      const d = { ...data, orderId, clueId };
      commit( 'mReplyInfo', d );
      return Promise.resolve( d );
    }
    return Promise.reject();
  }catch(e){
    return Promise.reject();
  }
}

/**
 * 功能：变更放款信息
 */
export const aLoan = async ( ctx: any ) => {

  const { commit, state: { info: { orderId, clueId } }, getters } = ctx;
  const { serveStatus: { next } } = getters;

  if( !orderId || next._value === 'LOAN' ) return Promise.reject( 'have not orderId or next is LOAN' );

  try{
    const { data: { code, data } } = await aClueDetail.loan( orderId );
    if( code == 200 ){
      const d = { ...data, orderId, clueId };
      commit( 'mLoanInfo', d );
      return Promise.resolve( d );
    }
    return Promise.reject();
  }catch(e){
    return Promise.reject();
  }
}

/**
 * 功能：变更结算信息
 */
export const aSettlement = async ( ctx: any ) => {

  const { commit, state: { info: { orderId, clueId } } } = ctx;

  if( !orderId ) return Promise.reject( 'have not orderId' );

  try{
    const { data: { code, data } } = await aClueDetail.settlement( orderId );
    if( code == 200 ){
      const d = { ...data, orderId, clueId };
      commit( 'mSettlementInfo', d );
      return Promise.resolve( d );
    }
    return Promise.reject();
  }catch(e){
    return Promise.reject();
  }
}

/**
 * 功能：跟进记录列表查询
 */
export const aFollowRecord = async ( ctx: any, payload: any ={} ) => {
  const { state: { info: { clueId } }, commit } = ctx;
  const params = { ...payload };
  const { recordTypeList=[], other } = params;
  if( !clueId ) return; 
  
  if( other ){ // 特殊处理其他
    params.recordTypeList = [ ...recordTypeList, ...other ];
    delete params.other;
  }

  try{
    const { data: { code, data } } = await aClueDetail.followRecord({
      clueId,
      followTypeList: [],
      flowStartTime: '',
      flowEndTime: '',
      pageNum: 1,
      pageSize: 10000,
      ...params,
    });

    if( code == 200 ){
      const list = handleFollowRecord( data.list || [] );
      commit( 'mRecordList', list );
      console.log( list, '---------mRecordList------------' );
      return Promise.resolve( list );
    }else{
      return Promise.reject()
    }
  }catch(e){
    console.log( e );
    return Promise.reject()
  }
}

/**
 * 功能：去写跟进
 */
export const aToggleFollowUp = async ( ctx: any, payload: any={} ) => {
  const { state: { info: { ptServerName, followerName } }, rootState: { userInfo: { isPlatform } }, commit } = ctx;

  // ptServerName -> 判断平台客服
  // followerName -> 是否分配员工

  if( isPlatform && !ptServerName && !followerName ){
    alert( '请先分配承接客服，再添加跟进记录' ); return; 
  }
  
  else if( !isPlatform && !followerName ){
    alert( '请先分配跟进员工，再添加跟进记录' ); return; 
  }

  commit( 'mToggleFollowUp', payload );
  
  return Promise.resolve( payload );
}








