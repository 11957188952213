let zyy = [
  {
    name: '线索管理',
    url: '/clue',
    sub: [
      // {
      //   name: '平台分发',
      //   url: '/CPlatform',
      //   route: '/CPlatform',
      //   nav3Key: 'CPlatform',
      //   sub: [
      //     {
      //       name: '投放线索',
      //       url: '/putClues',
      //       route: '/clue/putClues',
      //     },
      //     {
      //       name: '自然线索',
      //       url: '/naturalClues',
      //       route: '/clue/naturalClues',
      //     },
      //   ],
      // },
      {
        name: '线索公海',
        url: '/ptClueOpenSea',
        route: '/ptClueOpenSea',
        nav3Key: 'ptClueOpenSea',
        sub: [
          {
            name: '左心房投放线索',
            url: '/throwClues',
            route: '/clue/throwClues',
          },
          {
            name: '金蝶投放线索',
            url: '/KingdeeClues',
            route: '/clue/KingdeeClues',
          },
          {
            name: '自然线索',
            url: '/naturalClues',
            route: '/clue/naturalClues',
          },
        ]
      },
      {
        name: '销售线索',
        url: '/ptSalesClue',
        route: '/clue/SalesClue',
      },
      {
        name: '居间内部消化',
        url: '/CInternal',
        route: '/clue/CInternal',
      },
    ],
  },
  {
    name: '商机管理',
    url: '/business',
    sub: [
      {
        name: '平台分发',
        url: '/BPlatform',
        route: '/business/BPlatform',
      },
      {
        name: '内部消化',
        url: '/BInternal',
        route: '/business/BInternal',
      },
    ],
  },
  {
    name: '订单管理',
    url: '/order',
    sub: [
      {
        name: '平台分发',
        url: '/OPlatform',
        route: '/order/OPlatform',
      },
      {
        name: '内部消化',
        url: '/OInternal',
        route: '/order/OInternal',
      },
    ],
  },
  {
    name: '管理中心',
    url: '/management',
    sub: [
      {
        name: '机构管理',
        url: '/orgManagement',
        route: '/orgManagement',
        nav3Key: 'orgManagement',
        sub: [
          {
            name: '已开通机构',
            url: '/grant',
            route: '/management/grant',
          },
          {
            name: '未开通机构',
            url: '/unauthorized',
            route: '/management/unauthorized',
          },
        ],
      },
      {
        name: '员工管理',
        url: '/staffManagement',
        route: '/staffManagement',
        nav3Key: 'staffManagement',
        sub: [
          {
            name: '员工管理',
            url: '/staff',
            route: '/management/staff',
          },
          {
            name: '部门管理',
            url: '/department',
            route: '/management/department',
          },
        ]
      },
      {
        name: '离职继承',
        url: '/departureTransfer',
        route: '/departureTransfer',
        nav3Key: 'departureTransfer',
        sub: [
          {
            name: '离职继承',
            url: '/inherit',
            route: '/management/inherit',
          },
          {
            name: '已分配商机/订单',
            url: '/allocateInfo',
            route: '/management/allocateInfo',
          },
          {
            name: '已分配好友',
            url: '/allocatePartner',
            route: '/management/allocatePartner',
          },
        ]
      },
      {
        name: '在职继承',
        url: '/incumbencyTransfer',
        route: '/incumbencyTransfer',
        nav3Key: 'incumbencyTransfer',
        sub: [
          {
            name: '在职继承',
            url: '/incumbency',
            route: '/management/incumbency',
          },
          {
            name: '已分配商机/订单',
            url: '/INallocateInfo',
            route: '/management/INallocateInfo',
          },
          {
            name: '已分配好友',
            url: '/INallocatePartner',
            route: '/management/INallocatePartner',
          },
        ]
      },
      {
        name: '来源管理',
        url: '/channelManagement',
        route: '/channelManagement',
        nav3Key: 'channelManagement',
        sub: [
          {
            name: '来源管理',
            url: '/channel',
            route: '/management/channel',
          },
        ],
      },
      {
        name: '通话记录',
        url: '/callManagement',
        route: '/callManagement',
        nav3Key: 'callManagement',
        sub: [
          {
            name: '平台',
            url: '/call',
            route: '/management/call',
          },
          {
            name: '居间',
            url: '/call',
            route: '/management/calling',
          },
        ],
      },
      {
        name: '短信记录',
        url: '/messageManagement',
        route: '/messageManagement',
        nav3Key: 'messageManagement',
        sub: [
          {
            name: '平台',
            url: '/message',
            route: '/management/message',
          },
          {
            name: '居间',
            url: '/message',
            route: '/management/msg',
          },
        ],
      },
      {
        name: '线索自动分配',
        url: '/autoDistribute',
        route: '/autoDistribute',
        nav3Key: 'autoDistribute',
        sub: [
          {
            name: '线索自动分配',
            url: '/autoDistribute',
            route: '/management/autoDistribute',
          },
        ],
      }
    ],
  },
  {
    name: '投放管理',
    url: '/launch',
    sub: [
      {
        name: '投放清单',
        url: '/launchDetail',
        route: '/launchDetail',
        nav3Key: 'launchDetail',
        sub: [
          {
            name: '自营投放清单',
            url: '/launchClues',
            route: '/launch/Zclues',
          },
          {
            name: '独立投放清单',
            url: '/independent',
            route: '/launch/independent',
          },
          {
            name: 'API推送日志',
            url: '/apiPushLogList',
            route: '/launch/apiPushLogList',
          },
          // {
          //   name: '商机',
          //   url: '/launchBusiness',
          //   route: '/launch/Zbusiness',
          // },
          // {
          //   name: '订单',
          //   url: '/launchOrders',
          //   route: '/launch/Zorders',
          // },
        ],
      },
      // {
      //   name: '独立投放清单',
      //   url: '/independent',
      //   route: '/independent',
      //   nav3Key: 'independent',
      //   sub: [
      //     {
      //       name: '独立投放清单',
      //       url: '/independent',
      //       route: '/launch/independent',
      //     },
      //     // {
      //     //   name: '商机',
      //     //   url: '/launchIBusiness',
      //     //   route: '/launch/IBusiness',
      //     // },
      //     // {
      //     //   name: '订单',
      //     //   url: '/launchIOrder',
      //     //   route: '/launch/IOrder',
      //     // },
      //   ],
      // },
      {
        name: '参与投放公司',
        url: '/partakeManagement',
        route: '/partakeManagement',
        nav3Key: 'partakeManagement',
        sub: [
          {
            name: '参与投放公司',
            url: '/partake',
            route: '/management/partake',
          },
          {
            name: '归属日志',
            url: '/log',
            route: '/management/log',
          },
        ],
      },
      {
        name: '投放落地页',
        url: '/launchManagement',
        route: '/launchManagement',
        nav3Key: 'launchManagement',
        sub: [
          {
            name: '投放落地页',
            url: '/managementLand',
            route: '/management/land',
          },
          {
            name: '表单组件',
            url: '/formCom',
            route: '/management/formCom',
          },
          {
            name: '旧投放链接',
            url: '/launchConfig',
            route: '/management/launch',
          },
          {
            name: '代理商组',
            url: '/agentManagement',
            route: '/management/agentManagement',
          },
          {
            name: '投放城市组',
            url: '/citygroup',
            route: '/management/citygroup',
          },



          // {
          //   name: '房抵落地页可选城市配置',
          //   url: '/pageConfig',
          //   route: '/management/page',
          // },
          // {
          //   name: '信贷落地页可选城市配置',
          //   url: '/pageConfigCar',
          //   route: '/management/pagecar',
          // },

        ],
      },
      // {
      //   name: '投放落地页',
      //   url: '/landManagement',
      //   route: '/landManagement',
      //   nav3Key: 'landManagement',
      //   sub: [
      //     {
      //       name: '投放落地页',
      //       url: '/managementLand',
      //       route: '/management/land',
      //     },
      //   ],
      // },
    ],
  },
  {
    name: '批量导入',
    url: '/upload',
    isShow:false,
    sub: [
      {
        name: '批量导入',
        url: '/upload',
        route: '/upload',
        nav3Key: 'upload',
        sub: [
          {
            name: '批量导入',
            url: '/upload',
            route: '/upload/base',
          },
          {
            name: '导入历史',
            url: '/upload',
            route: '/upload/history',
          },
        ],
      },
    ],
  },
];
let agency = [
  {
    name: '线索管理',
    url: '/clue',
    sub: [
      {
        name: '待分配',
        url: '/waitDistribution',
        route: '/clue/waitDistribution',
      },
      {
        name: '线索公海',
        url: '/clueOpenSea',
        route: '/clue/clueOpenSea',
      },
      {
        name: '销售线索',
        url: '/salesLeads',
        route: '/clue/salesLeads',
      },
      {
        name: '已分配',
        url: '/allocated',
        route: '/clue/allocated',
      },
      {
        name: '我创建的',
        url: '/ICreated',
        route: '/clue/ICreated',
      },
      {
        name: '下属创建的',
        url: '/SubCreated',
        route: '/clue/SubCreated',
      },
    ],
  },
  {
    name: '商机管理',
    url: '/business',
    sub: [
      {
        name: '我跟进的',
        url: '/BFollowUp',
        route: '/business/BFollowUp',
      },
      {
        name: '我协助的',
        url: '/BAssist',
        route: '/business/BAssist',
      },
      {
        name: '外部跟进',
        url: '/BExternal',
        route: '/business/BExternal',
      },
    ],
  },
  {
    name: '订单管理',
    url: '/order',
    sub: [
      {
        name: '我跟进的',
        url: '/OFollowUp',
        route: '/order/OFollowUp',
      },
      {
        name: '我协助的',
        url: '/OAssist',
        route: '/order/OAssist',
      },
      {
        name: '外部跟进',
        url: '/OExternal',
        route: '/order/OExternal',
      },
    ],
  },
  {
    name: '管理中心',
    url: '/management',
    sub: [
      {
        name: '员工管理',
        url: '/staffManagement',
        route: '/management/agencyStaffManagement',
      },
      {
        name: '配置管理',
        url: '/settingManagement',
        route: '/management/settingManagement',
      },
      {
        name: '离职继承',
        url: '/departureTransfer',
        route: '/departureTransfer',
        nav3Key: 'departureTransfer',
        sub: [
          {
            name: '离职继承',
            url: '/inherit',
            route: '/management/inherit',
          },
          {
            name: '已分配商机/订单',
            url: '/allocateInfo',
            route: '/management/allocateInfo',
          },
          {
            name: '已分配好友',
            url: '/allocatePartner',
            route: '/management/allocatePartner',
          },
        ]
      },
      {
        name: '在职继承',
        url: '/incumbencyTransfer',
        route: '/incumbencyTransfer',
        nav3Key: 'incumbencyTransfer',
        sub: [
          {
            name: '在职继承',
            url: '/incumbency',
            route: '/management/incumbency',
          },
          {
            name: '已分配商机/订单',
            url: '/INallocateInfo',
            route: '/management/INallocateInfo',
          },
          {
            name: '已分配好友',
            url: '/INallocatePartner',
            route: '/management/INallocatePartner',
          },
        ]
      },
      {
        name: '来源管理',
        url: '/sourceManagement',
        route: '/sourceManagement',
        nav3Key: 'sourceManagement',
        sub: [
          {
            name: '来源管理',
            url: '/source',
            route: '/management/source',
          },
        ],
      },
      {
        name: '通话记录',
        url: '/callingManagement',
        route: '/callingManagement',
        nav3Key: 'callingManagement',
        sub: [
          {
            name: '通话记录',
            url: '/calling',
            route: '/management/callingAgency',
          },
        ]
      },
      {
        name: '短信记录',
        url: '/msgManagement',
        route: '/msgManagement',
        nav3Key: 'msgManagement',
        sub: [
          {
            name: '短信记录',
            url: '/msg',
            route: '/management/msgAgency',
          },
        ],
      },
      {
        name: '线索分配',
        url: '/clueDistribute',
        route: '/clueDistribute',
        nav3Key: 'clueDistribute',
        sub: [
          {
            name: '线索分配',
            url: '/clueDistribute',
            route: '/management/clueDistribute',
          },
        ],
      },
    ],
  },
  {
    name: '投放清单',
    url: '/launch',
    sub: [
      {
        name: '投放明细',
        url: '/launchDetail',
        route: '/launchDetail',
        nav3Key: 'launchDetail',
        sub: [
          {
            name: '投放明细',
            url: '/launchClues',
            route: '/launch/Aclues',
          },
          // {
          //   name: '商机',
          //   url: '/launchBusiness',
          //   route: '/launch/Abusiness',
          // },
          // {
          //   name: '订单',
          //   url: '/launchOrders',
          //   route: '/launch/Aorders',
          // },
        ],
      },
    ],
  },
  {
    name: '批量导入',
    url: '/upload',
    isShow:false,
    sub: [
      {
        name: '批量导入',
        url: '/upload',
        route: '/upload',
        nav3Key: 'upload',
        sub: [
          {
            name: '批量导入',
            url: '/upload',
            route: '/upload/base',
          },
          {
            name: '导入历史',
            url: '/upload',
            route: '/upload/history',
          },
        ],
      },
    ],
  },
  {
    name: '表单组件',
    url: '/formCom',
    route: '/formCom',
    nav3Key: 'formCom',
  },
];
export default {
  zyy,
  agency,
};
