import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';
import zzy from './zyy';
import agency from './agency';
import auth from './loginAuth';

let children = [...zzy, ...agency];
const routes = [
	// {
	// 	path: '/',
	// 	alias: ['/zyy', '/agency'],
	// 	component: () => import('@v/parentPage/base.vue'),
	// 	children,
	// 	// beforeEnter: async (to, from, next) => {
	// 	// 	if (!Object.keys(store.state.userInfo).length) {
	// 	// 		await store.dispatch('fetch_userInfo');
	// 	// 	}
	// 	// 	next();
	// 	// },
	// },
	{
		path: '/login',
		name: 'login',
		component: () => import('@v/parentPage/login.vue'),
		meta: {
			noLogin: true,
		},
	},
	{
		path: '/',
		name: 'home',
		component: () => import('@v/main/home.vue'),
		redirect: '/settlementManage',
		children:[{   //设置子路由
			path:'/settlementManage',
			name:'settlementManage',
			component:()=>import('@v/settlement/manage.vue'),
		  },
		  {    
			path:'/uploadRecords',
			name:'uploadRecords',
			component:()=>import('@v/settlement/uploadRecords.vue'),
		  },


		  
		  {
			path:'/statisticsResultCorp',
			name:'statisticsResultCorp',
			component:()=>import('@v/settlement/statisticsResultCorp.vue'),
		  },
		  {
			path:'/mgrStaffMgr',
			name:'mgrStaffMgr',
			component:()=>import('@v/settlement/mgrStaffMgr.vue'),
		  },
		  {
			path:'/mgrOptLog',
			name:'mgrOptLog',
			component:()=>import('@v/settlement/mgrOptLog.vue'),
		  }
		
		],
		meta: {
			noLogin: true,
		},
	},
	// {
	// 	path: '/',
	// 	name: 'settlementManage',
	// 	component: () => import('@v/settlement/manage.vue'),
	// 	meta: {
	// 		noLogin: true,
	// 		title: '结算统计',
	// 	},
	// },
	{
		path: '/dyUpload',
		name: 'dyUpload',
		component: () => import('@v/douyin/dmp/upload.vue'),
		meta: {
			noLogin: true,
			title: 'API推送日志',
		},
	},
	{
		path: '/landPage',
		name: 'landPage',
		component: () => import('@v/management/creatLand/landPage.vue'),
		meta: {
		  noLogin: true,
		},
	  },
	  {
		path: '/newFormPage',
		name: 'newFormPage',
		component: () => import('@v/management/creatForm/newFormPage.vue'),
		meta: {
		  noLogin: true,
		},
	  },
	{
		path: '/lqdlogin',
		name: 'lqdlogin',
		component: () => import('@v/parentPage/lqdlogin.vue'),
		meta: {
			noLogin: true,
		},
	},
	{
		path: '/editPartake',
		name: 'editPartake',
		component: () => import('@v/management/zyy/editPartake.vue'),
		meta: {
			noLogin: true,
		},
	},
	{
		path: '/auth',
		name: 'auth',
		component: () => import('@v/loginAuth/auth'),
		meta: {
			noLogin: true,
		},
		children: auth
	},
	{
		path: '/404',
		name: 'notF',
		component: () => import('@v/404.vue'),
		meta: {
			noLogin: true,
		},
	},
	// {
	// 	path: '/:pathMatch(.*)*',
	// 	redirect: '/404',
	// },
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});
router.beforeEach((to, from, next) => {
    let token = localStorage.getItem('zt');
    if (to.path == '/login') {
        next();
    }
    if (!token) {
        next('/login');
    } else {
        next()
    }
})
export default router;
